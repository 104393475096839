import { useState, useEffect } from 'react';
import {Link, NavLink} from 'react-router-dom';
import Logo from '../images/MedpelLogo.png';
import { links } from '../data';
import {GoThreeBars} from 'react-icons/go';
import {AiOutlineClose} from 'react-icons/ai'
import './navbar.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Navbar = () => {

    const [isNavShowing, setIsNavShowing] = useState(false);
    
    useEffect(()=>{
        AOS.init({
            duration: 3000, // values from 0 to 3000, with step 50ms
            delay: 50, // values from 0 to 3000, with step 50ms
            easing: 'ease-in-out-cubic', // default easing for AOS animations
        })
    },[])

    return (
        <nav>
            <div  data-aos='fade-in' className="container nav_container">
                <Link to='/' className='logo' onClick={() => isNavShowing(false)}>
                    <img src={Logo} alt="Medpel-logo" />
                </Link>

                <ul className={`nav_links ${isNavShowing ? 'show_nav' : 'hide_nav'}`}>
                    {
                        links.map((item)=> {
                            return <NavLink to={item.path}
                                        className={({isActive}) => isActive ? 'active_nav' : ''} 
                                        onClick={() => setIsNavShowing(prev => !prev)}
                                    >
                                    {item.name}
                                    </NavLink>;
                            })
                    }
                </ul>
                <button className="nav_toggle_btn" onClick={() => setIsNavShowing(prev => !prev)}>
                    {
                        isNavShowing ? <AiOutlineClose/> : <GoThreeBars/> 
                    }
                </button>
            </div>
        </nav>
    )
}

export default Navbar