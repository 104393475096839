import '../pages/home/home.css'

const YearDateUpdate = () => {
    return (
        <div className='footer-date'>
            {new Date().getFullYear()}
        </div>
    )
}

export default YearDateUpdate