import {Link} from 'react-router-dom';
import Logo from '../images/MedpelLogo.png';
import { FaFacebookF } from 'react-icons/fa';
import { AiOutlineTwitter } from 'react-icons/ai';
import { AiFillInstagram } from 'react-icons/ai';
import {AiFillLinkedin} from 'react-icons/ai';
import YearDate from './YearDateUpdate';
import '../pages/home/home.css';



const Footer = () => {
    return (
        <footer>
            <div className="container footer_container">
                <article>
                    <Link to='/' className='footer_logo'>
                        <img src={Logo} alt='Footer Logo' />
                    </Link>
                    <p>
                        Medpel Insurance Agency is a firm whose focus is in insurance, retirement benefits and advisory services to individuals and corporates, with a desire to improve our society for the greater good. Our fundamental objective is to deliver highly visible and useful information to the targeted recipients with a desire to help them make informed decisions. 
                    </p>
                </article>
                <article>
                    <h4>Quickinks</h4>
                        <div className="footer_links">
                        <Link to='/about' className='permalink'>About Us</Link>
                        <Link to='/corporate' className='permalink'>Corporate</Link>
                        <Link to='/individuals' className='permalink'>Individuals</Link>
                        <Link to='/contact' className='permalink'>Get in touch</Link>
                    </div>
                </article>
                <article>
                    <h4>Contact Information</h4>
                    <ul className='footer_contact_list'>
                        <li>P.O. Box 28165 - 00200, Nairobi</li>
                        <li>Vision Tower Muthithi Rd</li>
                        <li>2nd Floor West Wing</li>
                        <li>info@medpelinsurance.com </li>
                        <li>+254 000 00 00 00.</li>
                    </ul>
                </article>
                <article>
                    <h4>Let's Get Social</h4>
                    <div className="footer_socials">
                        <a href="https://bit.ly/medpelfacebook" target='_blank' rel="noreferrer">
                            <FaFacebookF/>
                        </a>
                        <a href="https://bit.ly/medpeltwitter" target='_blank'rel="noreferrer">
                            <AiOutlineTwitter/>
                        </a>
                        <a href="https://bit.ly/medpelinstagram" target='_blank'rel="noreferrer">
                            <AiFillInstagram/>
                        </a>
                        <a href="https://bit.ly/medpellinkedin" target='_blank'rel="noreferrer">
                            <AiFillLinkedin/>
                        </a>
                    </div>
                </article>
            </div>
            <div className="footer_copyright">
                <small> <YearDate/> Medpel Insurance Agency &copy; All Rights Reserved</small>
            </div>
        </footer>
    )
}

export default Footer