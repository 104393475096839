import '../components/categoryHeader.css';

const CategoryHeader = () => {
    return (
        <>
            <section className='categoryBg'>
                <div className='category-background'>
                    <div className="category-bg-prop"></div>
                </div>
            </section>
        </>
    )
}

export default CategoryHeader