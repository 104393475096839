import {Link} from 'react-router-dom';
import HeaderImage from '../images/homeImages/mainheader.webp';
import '../../src/pages/home/home.css';

const MainHeader = () => {

    return (
        <header className="main-header">
            <div className="container main-header-container">
                <div data-aos="fade-up" className="main-header-left">
                    <h1>Welcome To Medpel <br/> Insurance Agency</h1>
                    <h4>Adding Value To Life</h4>
                    <br/>
                    <h3>Secure Your Future</h3>
                    <Link to='/about' className="btn lg header-btn">Who We Are</Link>
                </div>
                <div className="main-header-right">
                    <div className="main-header-image">
                        <div data-aos="slide-down" className="header-styling-prop"></div>
                        <img data-aos="slide-left" src={HeaderImage} alt="" />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default MainHeader