import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop.jsx';
import { Link } from 'react-router-dom';
import CategoryHeader from '../../components/CategoryHeader.jsx';
import ProductEight from '../../components/ProductEight.jsx';
import '../product-category-one/productCategories.css';

const ProductCategoryFour = () => {
    return (
        <m.div
            initial={{x: "-100%"}}
            animate={{x: "-0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
        >
            <ScrollToTop/>
            <CategoryHeader/>
            <section className="category-page">
                <div className="category-desc">
                    <div className="category-links">
                        <Link to={'/'}>Home</Link> 
                        <span>&gt;</span>
                        <Link to={'/individuals'}>Individuals</Link>
                        <span>&gt;</span>
                        <Link to={'/product-eight'}>Product Eight</Link>
                    </div>
                    <div className="category-header">
                        <h2>Product Eight Categories</h2>
                    </div>
                </div>
                <div className="container category-cont">
                    <ProductEight/>
                </div>
            </section>
        </m.div>
    )
}

export default ProductCategoryFour