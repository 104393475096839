
export const links = [
    {
        name: "Home",
        path: '/'
    },
    {
        name: "Who We Are",
        path: '/about'
    },
    {
        name: 'Corporate',
        path: '/corporate'
    },
    {
        name: 'Individuals',
        path: '/individuals'
    },
    {
        name: "Get in touch",
        path: '/contact'
    }
]

const Program1 = require('./images/homeImages/risk.webp')
const Program2 = require('./images/homeImages/insurance.webp')
const Program3 = require('./images/homeImages/training.webp')
const Program4 = require('./images/homeImages/advisory.webp')
const Program5 = require('./images/homeImages/retirement-benefits.webp')
const Program6 = require('./images/homeImages/finance.webp')
const Program7 = require('./images/homeImages/retirement-training.webp')

export const programs = [
    {
        id:1,
        image: Program1,
        title: 'Risk Analysis',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:2,
        image: Program2,
        title: 'Insurance Advisory services',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:3,
        image: Program3,
        title: 'Training services',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:4,
        image: Program4,
        title: 'Advisory and investment ',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:5,
        image: Program5,
        title: 'Retirement benefits',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:6,
        image: Program6,
        title: 'Financial advisory services ',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:7,
        image: Program7,
        title: 'Post and pre-retirement training',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    }
]

export const corporatePrograms = [
    {
        id:1,
        image: Program1,
        title: 'sit amet',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/product-one"
    },
    {
        id:2,
        image: Program2,
        title: 'Lorem ipsum dolor',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/product-two"
    },
    {
        id:3,
        image: Program3,
        title: 'amet consectetur',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/product-three"
    },
    {
        id:4,
        image: Program4,
        title: 'dolor sit amet',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/product-four"
    }
]

export const individualPrograms = [
    {
        id:1,
        image: Program1,
        title: 'Lorem ipsum',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/product-five"
    },
    {
        id:2,
        image: Program2,
        title: 'Lorem ipsum dolor',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/product-six"
    },
    {
        id:3,
        image: Program3,
        title: 'ipsum dolor',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/product-seven"
    },
    {
        id:4,
        image: Program4,
        title: 'ipsum dolor',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/product-eight"
    },
    {
        id:5,
        image: Program5,
        title: 'ipsum dolor',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/product-nine"
    }
]


export const programOne = [
    {
        id:1,
        image: Program1,
        title: 'Lorem ipsum',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:2,
        image: Program2,
        title: 'Lorem ipsum dolor',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    }
]

export const programTwo = [
    {
        id:1,
        image: Program1,
        title: 'Lorem ipsum',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:2,
        image: Program2,
        title: 'Lorem ipsum dolor',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:3,
        image: Program3,
        title: 'ipsum dolor',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    }
]


export const programThree = [
    {
        id:1,
        image: Program1,
        title: 'Lorem ipsum',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:2,
        image: Program2,
        title: 'Lorem ipsum dolor',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:3,
        image: Program3,
        title: 'ipsum dolor',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:4,
        image: Program4,
        title: 'ipsum dolor',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    }
]


export const programFour = [
    {
        id:1,
        image: Program1,
        title: 'Lorem ipsum',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:2,
        image: Program2,
        title: 'Lorem ipsum dolor',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:3,
        image: Program3,
        title: 'ipsum dolor',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:4,
        image: Program4,
        title: 'ipsum dolor',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:5,
        image: Program5,
        title: 'ipsum dolor',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    }
]


export const programFive = [
    {
        id:1,
        image: Program1,
        title: 'Lorem ipsum',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:2,
        image: Program2,
        title: 'Lorem ipsum dolor',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    }
]


export const programSix = [
    {
        id:1,
        image: Program1,
        title: 'Lorem ipsum',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:2,
        image: Program2,
        title: 'Lorem ipsum dolor',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:3,
        image: Program3,
        title: 'ipsum dolor',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    }
]


export const programSeven = [
    {
        id:1,
        image: Program1,
        title: 'Lorem ipsum',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    }
]

export const programEight = [
    {
        id:1,
        image: Program1,
        title: 'Lorem ipsum',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:2,
        image: Program2,
        title: 'Lorem ipsum dolor',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:3,
        image: Program3,
        title: 'ipsum dolor',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    }
]


export const programNine = [
    {
        id:1,
        image: Program1,
        title: 'Lorem ipsum',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:2,
        image: Program2,
        title: 'Lorem ipsum dolor',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:3,
        image: Program3,
        title: 'ipsum dolor',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    },
    {
        id:4,
        image: Program4,
        title: 'ipsum dolor',
        desc: 'Lorem ipsum dolor sit amet consectetur.',
        path: "/main-product-one"
    }
]


const Patner1 = require('./images/patners/britam.png');
const Patner2 = require('./images/patners/ga.png');
const Patner3 = require('./images/patners/xplico.png');
const Patner4 = require('./images/patners/liberty.webp');
const Patner5 = require('./images/patners/cic.webp');
const Patner6 = require('./images/patners/jubilee.png');
const Patner7 = require('./images/patners/icea.png');

export const patners = [
    {
        id:1,
        image: Patner1,
        style: 'small-icon'
    },
    {
        id:2,
        image: Patner2,
        style: 'small-icon'
    },
    {
        id:3,
        image: Patner3,
        style: 'small-icon'
    },
    {
        id:4,
        image: Patner4,
    },
    {
        id:5,
        image: Patner5,
    },
    {
        id:6,
        image: Patner6,
    },
    {
        id:7,
        image: Patner7,
        style: 'small-icon'
    }
]

export const faqs = [
    {
        id: 1,
        question: "feugiat pretium nibh ipsum consequat nisl vel",
        answer: "at tellus at urna condimentum mattis pellentesque id nibh tortor id aliquet lectus proin nibh nisl condimentum id venenatis a condimentum"
    },
    {
        id: 2,
        question: "feugiat pretium nibh ipsum consequat nisl vel",
        answer: "at tellus at urna condimentum mattis pellentesque id nibh tortor id aliquet lectus proin nibh nisl condimentum id venenatis a condimentum"
    },
    {
        id: 3,
        question: "feugiat pretium nibh ipsum consequat nisl vel",
        answer: "at tellus at urna condimentum mattis pellentesque id nibh tortor id aliquet lectus proin nibh nisl condimentum id venenatis a condimentum"
    },
    {
        id: 4,
        question: "feugiat pretium nibh ipsum consequat nisl vel",
        answer: "at tellus at urna condimentum mattis pellentesque id nibh tortor id aliquet lectus proin nibh nisl condimentum id venenatis a condimentum"
    },
    {
        id: 5,
        question: "feugiat pretium nibh ipsum consequat nisl vel",
        answer: "at tellus at urna condimentum mattis pellentesque id nibh tortor id aliquet lectus proin nibh nisl condimentum id venenatis a condimentum"
    },
    {
        id: 6,
        question: "feugiat pretium nibh ipsum consequat nisl vel",
        answer: "at tellus at urna condimentum mattis pellentesque id nibh tortor id aliquet lectus proin nibh nisl condimentum id venenatis a condimentum"
    }
]