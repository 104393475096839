import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../pages/home/home.css';


function CTA() {
  useEffect(()=>{
    AOS.init({
      duration: 500,
    })
  },[])
  return (
    <section className='cta-home'>
      <div data-aos="slide-right" data-aos-once="true" className='container cta-home-conainer'>
        <h5>What Do You Want To Do Today?</h5>
      </div>
    </section>
  )
}

export default CTA