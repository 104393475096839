import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import NavBar from './components/NavBar.jsx';
import Home from './pages/home/Home.jsx';
import About from './pages/about/About.jsx';
import Corporate from './pages/corporate/Corporate.jsx';
import Individuals from './pages/individuals/Individuals.jsx';
// import Forms from './pages/forms/Forms.jsx';
import Contact from './pages/contact/Contact.jsx';
import Footer from './components/Footer.jsx';
import ProductOne from '../src/pages/product-category-one/ProductCategoryOne.jsx';
import ProductTwo from '../src/pages/product-category-two/ProductCategoryTwo.jsx';
import ProductThree from '../src/pages/product-category-three/ProductCategoryThree.jsx';
import ProductFour from '../src/pages/product-category-four/ProductCategoryFour.jsx';
import ProductFive from '../src/pages/product-category-five/ProductCategoryFive.jsx';
import ProductSix from '../src/pages/product-category-six/ProductCategorySix.jsx';
import ProductSeven from '../src/pages/product-category-seven/ProductCategorySeven.jsx';
import ProductEight from '../src/pages/product-category-eight/ProductCategoryEight.jsx';
import ProductNine from '../src/pages/product-category-nine/ProductCategoryNine.jsx';
import MainProduct from './pages/main-product-one/MainProduct.jsx';


const App = () => {
    return (
        <BrowserRouter>
            <NavBar/>
                <Routes>
                    <Route index element={<Home/>} />
                    <Route path='about' element={<About/>} />
                    <Route path='corporate' element={<Corporate/>} />
                    <Route path='individuals' element={<Individuals/>} />
                    <Route path='contact' element={<Contact/>} />
                    <Route path='product-one' element={<ProductOne/>}/>
                    <Route path='product-two' element={<ProductTwo/>}/>
                    <Route path='product-three' element={<ProductThree/>}/>
                    <Route path='product-four' element={<ProductFour/>}/>
                    <Route path='product-five' element={<ProductFive/>}/>
                    <Route path='product-six' element={<ProductSix/>}/>
                    <Route path='product-seven' element={<ProductSeven/>}/>
                    <Route path='product-eight' element={<ProductEight/>}/>
                    <Route path='product-nine' element={<ProductNine/>}/>
                    <Route path='main-product-one' element={<MainProduct/>}/>
                </Routes>
            <NavBar/>
            <Footer/>
        </BrowserRouter>
    )
}

export default App