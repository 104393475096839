import { useState } from 'react';
import {AiOutlinePlus} from 'react-icons/ai';
import {AiOutlineMinus} from 'react-icons/ai';
import '../pages/home/home.css';


const FAQ = ({question, answer}) => {

    const [isQuestionShowing, setQuestionShowing] = useState(false)

    return (
        <article className='faq-item' onClick={()=>setQuestionShowing(prev => !prev)}>
            <div>
                <h4>{question}</h4>
                <button className='faq-button'>
                    {
                        isQuestionShowing ? <AiOutlinePlus/> : <AiOutlineMinus/>
                    }
                </button>
            </div>
            {isQuestionShowing && <p>{answer}</p>}
        </article>
    )
}

export default FAQ