import HeaderImage from '../../images/homeImages/mainheader.webp';
import PageHeader from '../../components/PageHeader';
import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop.jsx';
import Comment from '../../components/Comment';
import './about.css';

const About = () => {
return(
    <
        // initial={{x: "-100%"}}
        // animate={{x: "-0%"}}
        // transition={{ duration: 0.9, delay:0.7}}
        // exit={{opacity: 1}}
    >
        <ScrollToTop/>
        <PageHeader image={HeaderImage} className={`about-page-cont`}>
        </PageHeader>

        <section className='about'>
            <div className="container about-container">
                <div className='about-page-prop-left'></div>
                <div className='about-page-prop-right'></div>
                <div className='about-desc-wrapper'>
                    <div className="about-desc-1">
                        <h2>Who We Are</h2>
                        <p>Medpel Insurance Agency</p>
                    </div>
                    <div className="about-desc-2">
                        <h4>Welcome to Medpel</h4>
                        <article>
                            Medpel Insurance Agency is a firm whose focus is in insurance, retirement benefits and advisory services to individuals and corporates, with a desire to improve our society for the greater good. Our fundamental objective is to deliver highly visible and useful information to the targeted recipients with a desire to help them make informed decisions. Our customers remain our key focus and keeping our promise of excellent customer service and quality deliverables remains our strategic positioning in the market. We are seeking to carry insurance business differently through:
                        </article>
                        <br />
                        <ul>
                            <li>High integrity levels & values.</li>
                            <li>Offering innovative products</li>
                            <li>Unbiased help for choice of underwriters-based on product & service in question.</li>
                            <li>Providing useful advice to clients in all areas of insurance</li>
                            <li>Embracing the needs of the customer</li>
                            <li>Synergistically ensuring more value for less.</li>
                        </ul>
                        <br/>
                        <h5>AREAS OF FOCUS INCLUDE BUT NOT LIMITED TO:</h5>
                        <br/>
                        <ul>
                            <li>Risk Analysis</li>
                            <li>Insurance Advisory services</li>
                            <li>Training services</li>
                            <li>Advisory and investment </li>
                            <li>Retirement benefits</li>
                            <li>Financial freedom advisory services </li>
                            <li>Post and pre-retirement training.</li>
                        </ul>
                        <br/>
                        <article>
                            Medpel Insurance Agency is seeking to be appointed as an intermediary for your organization in all your insurance needs. This will be in desire to offer a seamless process, and a great customer experience in the areas of employee benefits and allowing your company to carry on their mandate.
                        </article>
                        <br/>
                        <h5>OUR COMMITMENT TO YOU:</h5>
                        <br/>
                        <ul>
                            <li>Be your intermediary to the insurance companies for all insurance needs. Including but not limited to pension, group life, medical, motor, group personal accident, Work Injury Benefit Act, domestic and office insurance and travel.</li>
                            <li>Offer training to your staff on insurance matters including access and usage of insurance benefits</li>
                            <li>Collect intelligence information from various insurance companies for comparison and thereby getting the most competitive quotes at higher value for less for any insurance needs.</li>
                            <li>Timely payments of the claims as they arise in all areas of coverage. </li>
                            <li>Ensure prompt provision of statements in the case of retirement benefits & reconciliations.</li>
                            <li>Keep quality and accurate records on all the information that falls in as important to the client, including bio data, assets, and any other information supplied to us. </li>
                            <li>Ensure compliance with the regulators including, Retirement Benefits authority (RBA), and KRA- tax allowable amounts.</li>
                            <li>Provide post retirement strategies.</li>
                        </ul>
                        <br/>
                        <h5>OUR PURPOSE</h5>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, quisquam.</p>
                        <br />
                        <h5>OUR VISON</h5>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, quisquam.Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic, quisquam.</p>
                        <br />
                        <h5>WHY WE ARE IN BUSINESS?</h5>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, odio? Exercitationem deserunt natus sunt ab quisquam alias, a nam nobis.</p>
                        <br />
                        <h5>OUR VALUES</h5>
                        <br />
                        <ul>
                            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
                            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
                            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
                            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
                            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
                        </ul>
                    </div>
                </div> 
            </div>
        </section>
        <Comment/>
    </>
)
}


export default About