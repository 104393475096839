import { Link } from 'react-router-dom';
import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop.jsx';
import ProductMainHeader from "../../components/ProductMainHeader.jsx";
import HeaderImage from '../../images/homeImages/mainheader.webp';
import './mainproduct.css';

const MainProduct = () => {
    return (
        <m.div
            initial={{y: "100%"}}
            animate={{y: "0%"}}
            transition={{ duration: 0.9, delay:0.7}}
            exit={{opacity: 1}}
            className="main-product-page-cont"
        >
            <ScrollToTop/>
            <ProductMainHeader image={HeaderImage}/>
            <div className="product-page">
                <div className="container product-cont">
                    <div className="product-details-forms-main-cont">
                        <div className="product-details-desc-cont">
                            <div className="product-links">
                                <Link to={'/'}>Home</Link> 
                                <span>&gt;</span>
                                <Link>Main Product One</Link>
                            </div>
                            <br />
                            <h3>Product</h3>
                            <br />
                            <div className="product-details-desc">
                                <h4>Details</h4>
                                <br />
                                <h5>Lorem ipsum dolor sit.</h5>
                                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. A, error repellendus suscipit sapiente, exercitationem maxime placeat officiis quisquam dicta facere necessitatibus mollitia dolore tempore ipsum!</p>
                                <br />
                                <h5>Lorem, ipsum.</h5>
                                <ul>
                                    <li>Lorem ipsum dolor sit amet.</li>
                                    <li>Lorem ipsum dolor sit amet.</li>
                                    <li>Lorem ipsum dolor sit amet.</li>
                                    <li>Lorem ipsum dolor sit amet.</li>
                                </ul>
                                <h5>Lorem ipsum dolor sit.</h5>
                                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Totam, nisi.</p>

                                <p><b>Lorem ipsum</b>dolor sit amet consectetur adipisicing elit.</p>
                                <p><b>Lorem ipsum</b>dolor sit amet consectetur adipisicing elit.</p>
                                <p><b>Lorem ipsum</b>dolor sit amet consectetur adipisicing elit.</p>
                            </div>
                        </div>
                        <div className="products-form-cont">
                            <h2>Request a callback.</h2>
                            <form action="" className='product-form'>
                                <input type="text" name="" required placeholder="full name"/>
                                <input type="text" name="email" required placeholder="youremail@mail.com"/>
                                <input type="number" name="phone" required placeholder="0700 000 000"/>
                                <textarea name="" id="" cols="30" rows="10" placeholder="Your message"></textarea>
                                <div className="consent-items">
                                    <p>I authorize Medpel Insurance Agency to handle my personal data for my request.</p> <input className='consent-check' type="radio" name="consent" value="1" /> 
                                </div>
                                <button className='btn lg product-page-btn'>Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </m.div>
    )
}

export default MainProduct