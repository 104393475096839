import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { programs } from '../data';
import Card from '../UI/Card';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../pages/home/home.css';


const Products = () => {

    return (
        <section className="products">
            <div className="container products-container">
                <div data-aos="fade-up" className="products-wrapper">
                    {
                        programs.map(({id,image,title,desc,path})=>{
                            return (
                                <Card className=' product-card' id={id}>
                                    <img src={image} className="product-image" alt="" />
                                    <div className="product-details">
                                        <h4 className="product-title">{title}</h4>
                                        <p className="product-desc">{desc}</p>
                                        <Link to={path} className="btn sm products-home-btn">Learn More</Link>
                                    </div>
                                </Card>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default Products