import ScrollToTop from '../../components/ScrollToTop.jsx';
import Header from '../../images/homeImages/mainheader.webp';
import ProductsHeader from '../../components/ProuductsPageHeader.jsx';
import CorporateProducts from '../../components/CorporateProducts.jsx';
import CorporateProductsCarousel from '../../components/CorporateProductsCarousel.jsx';
import Comment from '../../components/Comment.jsx';
import '../../index.css';
import './corporate.css';

const Corporate = () => {
    return (
        <>
            <ScrollToTop/>
            <ProductsHeader image={Header} textOne={'Tailored For'} textTwo={'Corporate'}>
            </ProductsHeader>
            <section className="corporate-page">
                <div className="container corporate-cont">
                    <CorporateProducts/>
                </div>
                <div className="corporate-products-carousel">
                    <div className="products-carousel-header">
                        <p>RELATED PRODUCTS</p>
                        <h3>Corporate</h3>
                    </div>
                    <CorporateProductsCarousel/>
                </div>
            </section>
            <Comment/>
        </>
    )
}

export default Corporate