import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './productmainheader.css';

const ProductMainHeader = ({image,className}) => {

    useEffect(()=>{
        AOS.init({
            duration:1000
        })
    },[])

    return (
        <section className='product-page-header-main-cont'>
            <div className="product-page-header-cont">
                <div data-aos="fade-down" className={`product-page-header-image-cont ${className}`}>
                    <img src={image} alt="" className="product-page-image" />
                </div>
            </div>
        </section>
    )
}

export default ProductMainHeader