import Slider from 'react-slick';
import { corporatePrograms } from '../data';
import { Link } from 'react-router-dom';
import Card from '../UI/Card';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import '../index.css';
import './corporateproductscarousel.css';

const CorporateProductsCarousel = () => {
    const settings = {
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
                }
            }
        ]
    };

    return (
        <>
            <Slider {...settings}>
                    {
                        corporatePrograms.map(({id,image,title,desc,path})=>{
                            return (
                                <Card className=' bs-product-card' id={id}>
                                    <img src={image} className="bs-product-image" alt="" />
                                    <div className="bs-product-details">
                                        <h4 className="bs-product-title">{title}</h4>
                                        <p className="bs-product-desc">{desc}</p>
                                        <Link to={path} className="btn sm bs-products-home-btn">Learn More</Link>
                                    </div>
                                </Card>
                            )
                        })
                    }
            </Slider>
        </>
    )
}

export default CorporateProductsCarousel
