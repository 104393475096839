import HeaderImage from '../../images/homeImages/mainheader.webp';
import PageHeader from '../../components/PageHeader';
import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop.jsx';
import './contact.css';

const Contact = () => {
    return (
        <>
            <ScrollToTop/>
            <PageHeader image={HeaderImage}/>
            <section className='contact'>
                <div className="container contact-cont">
                    <div className="cont-detail-wrapper">
                        <div className="detail-left">
                            <div className="form-cont">
                                <div className="form-header">
                                    <h2>Our Call Centre Number</h2> 
                                    <p>+254 000 000 000</p>
                                    <p>Opening hours: Mon - Fri 08:15 - 17:00</p>
                                    <p>Weekends: Sat: 09:00 – 13:00</p>
                                    <p>Public Holidays – Closed</p>
                                </div>
                                <div className="form">
                                    <form action="">
                                        <input type="text" name='name' placeholder='Full name' required/>     
                                        <input type="text" name='email' placeholder='Email' required/>
                                        <input type="number" name='phone' placeholder='Phone Number' required/>
                                        <textarea name="message" id="" cols="30" rows="10" placeholder='Your Message' required></textarea>
                                        <button className="btn lg contact_form_btn">Send Message</button> 
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="detail-right">
                            <div className="map-cont">
                                <div className="map-header">
                                    <h2>Head office</h2> 
                                    <p>Lorem, ipsum dolor.</p>
                                    <p>P.O. Box 28165 - 00200, Nairobi, Kenya</p>
                                    <p>Contact Centre: +254 000 000 000</p>
                                    <p>Email: info@medpelinsurance.com</p>
                                    <p>t (+254) 00 000 0000</p>
                                </div>
                                <div className="map">
                                    <iframe data-aos='fade-up' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.845537551354!2d36.804154814305136!3d-1.2652543359593544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f173c0122288b%3A0x7aef475b6ca0154b!2sVision%20Towers!5e0!3m2!1sen!2ske!4v1676463651034!5m2!1sen!2ske" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="Location"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact