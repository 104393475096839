import {motion as m} from 'framer-motion';
import ScrollToTop from '../../components/ScrollToTop.jsx'
import MainHeader from '../../components/MainHeader.jsx'
import CTA from '../../components/CTA.jsx';
import Products from '../../components/Products.jsx';
import Patners from '../../components/PatnersCarousel.jsx';
import FAQs from '../../components/FAQs.jsx';
import Comment from '../../components/Comment.jsx';
import './home.css';


const Home = () => {
    return (
        <m.div>
            <ScrollToTop/>
            <MainHeader/>
            <CTA/>
            <Products/>
            <Patners/>
            <FAQs/>
            <Comment/>
        </m.div>
    )
}

export default Home