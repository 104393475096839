import CommentIcon from '../images/chat.png';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import "./comment.css";

const Comment = () => {

    return (
        <>
            <span data-aos="slide-left" className="icon">
                <Link to={'https://m.me/medpelinsuranceagency'} target="_blank">
                    <img src={CommentIcon} alt="comment icon" />
                </Link>
            </span>
        </>
    )
}

export default Comment