import ScrollToTop from '../../components/ScrollToTop.jsx';
import Header from '../../images/homeImages/mainheader.webp';
import ProductsHeader from '../../components/ProuductsPageHeader.jsx';
import IndividualProducts from '../../components/IndividualProducts.jsx';
import IndividualProductsCarousel from '../../components/IndivudualProductsCarousel.jsx';
import Comment from '../../components/Comment';
import '../../index.css';
import './individuals.css'

const Individuals = () => {
    return (
        <>
            <ScrollToTop/>
            <ProductsHeader image={Header} textOne={'Tailored For'} textTwo={'Individuals'}>
            </ProductsHeader>
            <section className="individual-page">
                <div className="container individual-cont">
                    <IndividualProducts/>
                </div>
                <div className="individual-products-carousel">
                    <div className="products-carousel-header">
                        <p>RELATED PRODUCTS</p>
                        <h3>Individuals</h3>
                    </div>
                    <IndividualProductsCarousel/>
                </div>
            </section>
            <Comment/>
        </>
    )
}

export default Individuals