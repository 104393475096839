import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../index.css';


const ProuductsPageHeader = ({image,className, textOne, textTwo}) => {

    useEffect(()=>{
        AOS.init({
            duration:1000
        })
    },[])

    return (
        <section className='products-header-main-cont'>
            <div className="products-header-cont">
                <div data-aos="fade-up" className="products-header-desc">
                    <div className="products-desc-wrapper">
                        <p>{textOne}:</p>
                        <h2>{textTwo}</h2>
                    </div>
                </div>
                <div data-aos="slide-down" className="products-header-prop"></div>
                <div data-aos="slide-left" className={`products-header-image-cont ${className}`}>
                    <img src={image} alt="" className="products-image" />
                </div>
            </div>
        </section>
    )
}

export default ProuductsPageHeader