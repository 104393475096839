import Slider from 'react-slick';
import {patners} from '../data';
import SectionHeader from './SectionHeader';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './patnersCarousel.css'
import '../pages/home/home.css'

const PatnersCarousel = () => {
    const settings = {
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
                infinite: true,
                dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
                }
            }
        ]
    };

    return (
        <>
            <SectionHeader className={'patners-carousel-heading'}>
                <>Our Patners</>
            </SectionHeader>

            <Slider {...settings}>
                {
                    patners.map(({image, style, id})=>{
                    return <div className="patners-logo-container" key={id}>
                        <div className="patners-logo-card">
                            <img src={image} className={`${style}`} alt="patners icon" />
                        </div>
                    </div>
                    })
                }
            </Slider>
        </>
    )
}

export default PatnersCarousel
