import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../pages/home/home.css';

const SectionHeader = ({className, children}) => {

    useEffect(()=>{
        AOS.init({
            duration:1000
        })
    }, [])

    return (
        <div className={`section-header ${className}`}>
            <p className='section-heading'>{children}</p>
        </div>
    )
}

export default SectionHeader