import SectionHeader from "./SectionHeader";
import {faqs} from '../data';
import FAQ from '../components/FAQ.jsx';
import '../pages/home/home.css';

const FAQs = () => {
    return (
        <>
            <SectionHeader>
                <>FAQS</>
            </SectionHeader>
            <section className="faqs">
                <div className="container faqs-container">
                    <div className="faqs-wrapper">
                        {
                            faqs.map(({id, question, answer})=>{
                                return <FAQ key={id} question={question} answer={answer}/>
                            })
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default FAQs